<app-header-nav current_tab="/customer-preference" show_model_select="true"></app-header-nav>
<div class="page-container">
  <div class="embed-page-container">
    <app-titles
      title="Top Preference by Customer Segment"
      subtitle="View customer's top preference within a category"
      action="Choose a customer segmentation to view top preferences within a category. Additionally, sort the categories by the average significance to customers or by most misaligned with current offering. Easily view current offering when segmented on “preferred grade”"
    />
    <app-embed *ngIf="selected_model === '311D'" src="{{path_311D}}/3CustomerPreferencesEmbed12"/>
    <app-embed *ngIf="selected_model === '311D_OP'" src="{{path_311D_OP}}/3CustomerPreferencesEmbed12"/>
    <app-embed *ngIf="selected_model === '400D'" src="{{path_400D}}/3CustomerPreferencesEmbed12"/>
    <app-embed *ngIf="selected_model === 'SIENNA'" src="{{path_SIENNA}}/3CustomerPreferencesEmbed12"/>
  </div>
  <div class="embed-page-container">
    <app-titles
      title="Feature Preference by Customer Segment"
      subtitle="View customer preferences (%) for each feature within a category"
      action="Utilize filters to choose a specific category to explore, understand how preferences change by customer segments, and easily view current offering when segmented on “preferred grade”"
    />
    <app-embed *ngIf="selected_model === '311D'" src="{{path_311D}}/3CustomerPreferencesEmbed22"/>
    <app-embed *ngIf="selected_model === '311D_OP'" src="{{path_311D_OP}}/3CustomerPreferencesEmbed22"/>
    <app-embed *ngIf="selected_model === '400D'" src="{{path_400D}}/3CustomerPreferencesEmbed22"/>
    <app-embed *ngIf="selected_model === 'SIENNA'" src="{{path_SIENNA}}/3CustomerPreferencesEmbed12"/>
  </div>
  <div class="embed-page-container">
    <app-titles
      title="Customer Value (CV) Score: Quadrants"
      subtitle="A grouping of features into one of four quadrants (descriptions in chart below): 1. Anchors 2. Value-Oriented, Enthusiasts 3. Crowd Pleasers 4. Explore Simplification by considering the portion of customers who prefer a feature and how important it is to those people"
      action="Select a category or view all categories at once to see which quadrant each feature falls into for the customer segment of interest"
    />
    <app-embed *ngIf="selected_model === '311D'" src="{{path_311D}}/4CVScoreQuadrants"/>
    <app-embed *ngIf="selected_model === '311D_OP'" src="{{path_311D_OP}}/4CVScoreQuadrants"/>
    <app-embed *ngIf="selected_model === '400D'" src="{{path_400D}}/4CVScoreQuadrants"/>
    <app-embed *ngIf="selected_model === 'SIENNA'" src="{{path_SIENNA}}/4CVScoreQuadrants"/>
  </div>
  <div class="embed-page-container">
    <app-titles
      title="Customer Value (CV) Score: Visualization"
      subtitle="Visualize where each feature lies within the four quadrants by considering the portion of customers who prefer a feature and how important it is to those people"
      action="Select a category to visualize where each feature falls on popularity and importance for the customer segment of interest"
    />
    <app-embed *ngIf="selected_model === '311D'" src="{{path_311D}}/4CVScore2x2"/>
    <app-embed *ngIf="selected_model === '311D_OP'" src="{{path_311D_OP}}/4CVScore2x2"/>
    <app-embed *ngIf="selected_model === '400D'" src="{{path_400D}}/4CVScore2x2"/>
    <app-embed *ngIf="selected_model === 'SIENNA'" src="{{path_SIENNA}}/4CVScore2x2"/>
  </div>
</div>
