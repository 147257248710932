import {Component} from '@angular/core';
import {scenarioBaselineGrid, scenarioBody, displayHeaders} from 'src/app/interfaces/scenario';
import {FormControl} from '@angular/forms';
import {map, Observable, startWith} from 'rxjs';
import {ALL_VEHICLES, COLOR_MAPPINGS, DEFAULT_VEHICLE} from '../../config/config';
import {ApiService} from '../../services/api.service';
import {UIStateService} from 'src/app/services/ui-state.service';
import {Router} from '@angular/router';
import {MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css'],
})
export class GridComponent {
  baseline: scenarioBaselineGrid = null;
  scenario_name = '';
  show_spinner = false;
  segments = [{}];
  models: string[] = ALL_VEHICLES;
  selected_model = DEFAULT_VEHICLE;
  selected_segment = '';
  selected_category: string = '';
  filtered_options: Observable<string[]>;
  all_categories: string[] = [];
  categories_mapping: { [key: string]: string[] } = {};
  all_columns: string[] = [];
  datasource = [];
  volume: number = 400000;
  ordered_columns: { [key: string]: string[]; }[] = [];
  ordered_display_headers: [displayHeaders][] = [];
  mat_table_headers_array: string[][] = [];
  border_right_array: number[] = [];
  form_control: FormControl;
  category_value: string = '';
  show_table: boolean = false;
  has_validation_error: boolean = true;
  validation_error_message: string = '';
  claims;
  color_mappings = {};
  validation_failed = false;

  constructor(
    private apiService: ApiService,
    readonly appState: UIStateService,
    readonly router: Router,
    public dialog: MatDialog
  ) {
    this.appState
      .getState$()
      .pipe()
      .subscribe((state) => {
        if (Object.keys(state).length !== 0) {
          this.claims = state.claims;
          this.color_mappings = COLOR_MAPPINGS;
          this.baseline = JSON.parse(JSON.stringify(state.baseline)); // deep copy to avoid overwrites

          this.generateCategoryMapping();
          this.all_categories = Object.keys(this.categories_mapping);
          this.segments = Object.keys(state.segments).map((key) => ({
            segment: key,
            segment_displayed: state.segments[key],
          }));
          this.form_control = new FormControl({ value: '', disabled: true });
          this.filtered_options = this.form_control.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
          );
          this.form_control.enable();
          this.ordered_columns = this.baseline.column_name_order.reduce((acc, el) => {
            const matchedKey = Object.keys(this.baseline.column_name_clean).find((key) => key.indexOf(el) !== -1);
            const label = el.replace('_', ' ');
            acc.push({ label, arr: this.baseline.column_name_clean[matchedKey] });
            return acc;
          }, []);
        }
      });
  }

  // iterate through each category/feature to get a mapping from category to features
  generateCategoryMapping() {
    this.categories_mapping = {};
    Object.entries(this.baseline.index_data).forEach((entry) => {
      let [key] = entry;
      key = key.substring(1, key.length - 1);
      const [category, feature] = key.split('\', \'');
      this.categories_mapping[category] =
        this.categories_mapping.hasOwnProperty(category)
          ? [...this.categories_mapping[category], feature]
          : [feature];
    });
  }

  buildColumns(): void {
    this.all_columns = [];
    this.all_columns = this.baseline.columns.map((arr: string[]) => arr.join('_'));
    this.all_columns.unshift(`featureName`);

    // convert all displayable options into header rows;
    this.ordered_display_headers = this.buildNestedDisplayHeaders();
    this.mat_table_headers_array = this.ordered_display_headers.map((headerRow) => {
      return headerRow.map(el => `${el.label}${el.parent}`);
    });

    let borderIndex = 0;
    this.border_right_array = this.ordered_display_headers[0].reduce((acc, current, index) => {
      if (index > 0) { borderIndex = borderIndex + current.span; }
      acc.push(borderIndex);
      return acc;
    }, []);
  }

  buildNestedDisplayHeaders() {
    const newDisplayHeaders = this.baseline.columns.reduce((acc: [displayHeaders][], current) => {
      current.forEach((col, i) => {
        let parent = 'none';
        if (i > 0) {
          parent = '';
          for (let k = 0; k < i; k++) {
            parent = parent + current [k] + `${k === i - 1 ? '' : '_'}`;
          }
        }
        if (!acc[i]) {
          acc.push([{label: col, span: 1, parent}]);
        } else {
          const displayIndex = acc[i].findIndex((el) => {
            return el.label === col && el.parent === parent;
          });
          if (displayIndex === -1) {
            acc[i].push({label: col, span: 1, parent});
          } else {
            acc[i][displayIndex].span++;
          }
        }
      });
      return acc;
    }, []);
    this.ordered_columns.forEach((col: any, i) => {
      newDisplayHeaders[i].unshift({label: col.label, span: 1});
    });
    return newDisplayHeaders;
  }

  buildRowObject(
    columns: string[],
    data: number[]
  ): { [key: string]: number }[] {
    return data.map((val, index) => ({
      [columns[index]]: val,
    }));
  }

  generateDataSource(target_category: string) {
    const datasource = [];
    Object.entries(this.baseline.index_data).forEach((entry) => {
      const [key, value] = entry;
      const subKey = key.substring(1, key.length - 1);
      const [category, feature] = subKey.split('\', \'');

      if (category === target_category) {
        const columnsToUse = this.all_columns;
        datasource.push(
          Object.assign(
            { featureName: feature, category },
            ...this.buildRowObject(
              columnsToUse.filter(
                (column) => column !== 'featureName' && column !== 'category'
              ),
              value
            )
          )
        );
      }
    });
    return datasource;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.all_categories.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  checkShowTable() {
    this.show_table =
      this.selected_model !== '' &&
      this.selected_category !== '' &&
      this.scenario_name !== '' &&
      this.selected_segment !== '';
  }

  onCategoryChange(value) {
    this.selected_category = value;
    this.has_validation_error = false;
    this.validation_error_message = '';
    this.computeDataSource();
    this.checkShowTable();
  }

  onSegmentChange() {
    this.checkShowTable();
  }

  onModelChange() {
    this.selected_segment = '';
    this.selected_category = '';
    this.form_control.disable();
    this.apiService.getBaseline(this.selected_model).subscribe((data) => {
      this.appState.setState({
        vehicle: this.selected_model,
        baseline: data.grid,
      });
    });
    this.checkShowTable();
  }

  getColSpan(column) {
    return this.all_columns.filter(att => att.includes(column)).length;
  }

  computeDataSource() {
    this.buildColumns();
    this.datasource = this.generateDataSource(this.selected_category);
  }

  // when a cell is clicked, have corresponding behavior change
  changeElement(element, column) {
    this.datasource.forEach((row, index) => {
      if (
        row.featureName === element.featureName &&
        row.category === element.category
      ) {
        if (this.isColorCategory(element.category)) {
          switch (row[column]) {
            case 0:
              this.datasource[index][column] = 2;
              break;
            case 2:
              this.datasource[index][column] = 0;
              break;
          }
        } else {
          switch (row[column]) {
            case 0:
              this.datasource[index][column] = 1;
              break;
            case 1:
              this.datasource[index][column] = 2;
              break;
            case 2:
              this.datasource[index][column] = 0;
              break;
          }
        }
      }
    });
  }

  clearOption() {
    this.selected_category = '';
    this.datasource = [];
    this.category_value = '';
    this.show_table = false;
  }

  resetGrid() {
    this.datasource.forEach((row, index) => {
      const name = `'${row.category}', '${row.featureName}'`;
      let count = 0;
      Object.entries(row).forEach(([key]: [string, string]) => {
        if (key !== 'featureName' && key !== 'category') {
          this.datasource[index][key] = this.baseline.index_data[name][count];
          count++;
        }
      });
    });
  }

  // converts dataSource back to baseline format for submission
  formatScenario() {
    const baselineCopy = JSON.parse(JSON.stringify(this.baseline));
    this.datasource.forEach((row) => {
      const category = row.category;
      const feature = row.featureName;
      const values = [];
      Object.entries(row).forEach(([key, value]: [string, string]) => {
        if (key !== 'category' && key !== 'featureName') {
          // tslint:disable-next-line:radix
          values.push(parseInt(value));
        }
      });
      baselineCopy.index_data[`'${category}', '${feature}'`] = values;
    });

    return baselineCopy;
  }

  isColorCategory(category: string) {
    return category === 'Exterior color' || category === 'Interior color' || category === 'Interior / seat color' ;
  }

  // check if an option appears exactly num times across all grades
  hasExactAppearances(option, num): boolean {
    return this.all_columns.filter((el) => el !== 'featureName').every((key) => {
      let count_of_appearances = 0;
      this.datasource.forEach(row => row[key] === option && count_of_appearances++);
      return count_of_appearances === num;
    });
  }

  // check if an option appears at least num times across all grades
  hasAtLeastAppearances(option, num): boolean {
    return this.all_columns.filter((el) => el !== 'featureName').every((key) => {
      let count_of_appearances = 0;
      this.datasource.forEach(row => row[key] === option && count_of_appearances++);
      return count_of_appearances >= num;
    });
  }

  hasNoOptAboveStandard() {
    return this.all_columns.filter((el) => el !== 'featureName').every((key) => {
      let has_seen_optional = false;

      return this.datasource.every((row) => {
        if (row[key] === 2) { has_seen_optional = true; }
        return !(row[key] === 1 && has_seen_optional);
      });
    });
  }

  checkScenarioIsValid(): boolean {
    if (this.isColorCategory(this.selected_category)) {
      // for colors: you must select at least one feature as optional within each vehicle type/grade combination”
      if (!this.hasAtLeastAppearances(2, 1)) {
        this.has_validation_error = true;
        this.validation_error_message =
          'Please ensure at least 1 feature is optional for all color grades';
        return false;
      }
    } else {
      // for non-colors: you must select exactly one feature as standard within each vehicle type/grade combination”
      if (!this.hasExactAppearances(1, 1)) {
        this.has_validation_error = true;

        this.validation_error_message =
          'Please ensure exactly 1 feature is standard for all grades';
        return false;
      }
      if (!this.hasNoOptAboveStandard()) {
        this.has_validation_error = true;

        this.validation_error_message =
          'Please ensure no feature is optional below a standard level for all grades';
        return false;
      }
    }
    this.has_validation_error = false;
    this.validation_error_message = '';

    return true;
  }

  submitScenario() {
    if (!this.checkScenarioIsValid()) {
      console.log(this.formatScenario());
      return;
    }
    this.show_spinner = true;

    const body: scenarioBody = {
      new_baseline: this.formatScenario(),
      vehicle: this.selected_model,
      user_name: this.claims.email,
      submitted_at: Date(),
      scenario_name: this.scenario_name,
      selected_category: this.selected_category,
      is_single: 'false',
      scenario_volume: this.volume,
      segment: this.selected_segment,
    };

    this.apiService.submitScenario(body).subscribe(
      (data) => {
        this.show_spinner = false;
        this.appState.setState({
          user_name: data.user_name,
          scenario_name: data.scenario_name,
        });
        this.router.navigateByUrl('/scenario-output', {
          state: {
            user_name: this.claims.email.toUpperCase(),
            scenario_name: this.scenario_name,
            vehicle: this.selected_model
          },
        });
      },
      (error) => {
        console.log('Error', error);
        this.show_spinner = false;
        // this.dialog.open(DialogScenarioError, {
        //   width: '250px',
        //   enterAnimationDuration: '300ms',
        //   exitAnimationDuration: '300ms',
        // });
      },
      () => {}
    );
  }
}
// @Component({
//   selector: 'dialog-scenario-error',
//   templateUrl: 'dialog-scenario-error.html',
//   standalone: true,
//   imports: [MatDialogModule, MatButtonModule],
// })
// export class DialogScenarioError {
//   constructor(public dialogRef: MatDialogRef<DialogScenarioError>) {}
// }
